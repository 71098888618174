import React, { useCallback, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import { useSchoolStore } from '../Stores/SchoolStore'

export default function SchoolDisconnectDialog({ open, setOpen }) {
  const showMsg = useSnackbar().enqueueSnackbar
  const school = useSchoolStore((state) => state.school)
  const handleClick = useCallback((code) => {
    setOpen(false)
    setValue('')

    if (code !== school.code) {
      showMsg(
        'Der Schul-Code stimmt nicht mit der verbundenen Schule überein.',
        {
          variant: 'warning',
          autoHideDuration: 8000,
        },
      )
    } else {
      useSchoolStore.getState().removeSchool()
      showMsg('Schulverknüpfung entfernt.', { variant: 'success' })
    }
  }, [])
  const [value, setValue] = useState('')

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Schulverbindung Entfernen
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Um die Schulverbindung dieses Geräts zu entfernen, geben sie zur
          Bestätigung bitte erneut den Schul-Code ein.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="code"
          label="Schul-code"
          fullWidth
          value={value}
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          onChange={(e) => setValue(e.target.value.trim())}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Abbrechen</Button>
        <Button
          disabled={!value}
          onClick={() => handleClick(value)}
          color="primary"
        >
          Schulverbindung Entfernen
        </Button>
      </DialogActions>
    </Dialog>
  )
}
