import React, { useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useLoadSchool, useSchoolStore } from '../Stores/SchoolStore'

export function SchoolConnectDialog({ open, setOpen }) {
  const school = useSchoolStore((state) => state.school)
  const loadSchool = useLoadSchool()
  const showMsg = useSnackbar().enqueueSnackbar
  const history = useHistory()
  const { urlSchoolCode } = useParams()
  const [value, setValue] = useState('')

  const loadSchoolMsg = (c) => {
    loadSchool(c).then((s) =>
      showMsg(`Dieses Gerät wurde mit ${s.name} verbunden.`, {
        variant: 'success',
      }),
    )
  }

  // Apply school code from URL
  useEffect(() => {
    if (urlSchoolCode && !school) {
      loadSchoolMsg(urlSchoolCode)
    }
    if (urlSchoolCode) history.push(`/`)
  }, [urlSchoolCode, school, urlSchoolCode, history])

  useEffect(() => {
    const { lastSync } = useSchoolStore.getState()
    const now = new Date()
    // Sync every 3 hours
    if (school && lastSync && now - lastSync > 60000 * 60 * 3) {
      console.log('Performing automatic school sync')
      loadSchool(school.code)
    }
  }, [school, loadSchool])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Schulverbindung hinzufügen
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Um eine Schulverbindung her zu stellen, geben sie bitte den
          entsprechenden Schul-Code ein.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="code"
          label="Schul-code"
          fullWidth
          value={value}
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          onChange={(e) => setValue(e.target.value.trim())}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Abbrechen</Button>
        <Button
          disabled={!value}
          onClick={() => {
            setValue('')
            setOpen(false)
            loadSchool(value)
          }}
          color="primary"
        >
          Mit Schule Verbinden
        </Button>
      </DialogActions>
    </Dialog>
  )
}
