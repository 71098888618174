import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Delete as DeleteIcon } from '@material-ui/icons'
import Fab from '@material-ui/core/Fab'
import { useRecentBooksStore } from '../Stores/RecentBooksStore'
import './RecentBookCover.css'

export function RecentBookCover({ book, onClick }) {
  return (
    <Card style={{ width: 200, height: 290, position: 'relative' }}>
      {/* <Fab
        className="recentbook-delete"
        size="small"
        style={{ position: 'absolute', right: 0, zIndex: 9999, top: 0 }}
        onClick={() => useRecentBooksStore.getState().removeBook(book)}
      >
        <DeleteIcon className="delete-icon" />
      </Fab> */}
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          alt="Cover"
          height="200"
          image={book.coverUrl}
          title={book.code}
          className="cover-img"
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="h2">
            {book.title}
          </Typography>
          <Typography
            variant="caption"
            className={book.active === false ? 'school expired' : 'school'}
          >
            {book.active === false ? 'Lizenzcode Abgelaufen!' : book.school}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  )
}
