import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useServiceWorkerStore } from '../Stores/ServiceWorkerStore'

export function ServiceWorkerNotification() {
  const store = useServiceWorkerStore()
  const onClose = () => useServiceWorkerStore.setState({}, true)

  if (store.severity)
    return (
      <Snackbar open={store.message !== undefined} onClose={onClose}>
        <MuiAlert elevation={6} variant="filled" severity={store.severity}>
          {store.message}
        </MuiAlert>
      </Snackbar>
    )
  return (
    <Snackbar
      open={store.message !== undefined}
      autoHideDuration={6000}
      onClose={onClose}
      message={store.message}
    />
  )
}
