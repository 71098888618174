import { useLayoutEffect, useState } from 'react'

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    const deferredUpdateSize = () => setTimeout(() => updateSize(), 400)

    window.addEventListener('resize', updateSize)
    // Fullscreen events seem to be required for Safari
    document.addEventListener('fullscreenchange', deferredUpdateSize)
    document.addEventListener('webkitfullscreenchange', deferredUpdateSize)

    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize)
      document.removeEventListener('fullscreenchange', deferredUpdateSize)
      document.removeEventListener('webkitfullscreenchange', deferredUpdateSize)
    }
  }, [])
  return size
}
