import create from 'zustand'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { useRecentBooksStore } from './RecentBooksStore'

const LOCAL_STORAGE_KEY = 'school.v1'
const LOCAL_STORAGE_KEY_SYNC = 'school_sync'

function loadFromStorage() {
  try {
    // This is a good place to migrate, if necessary
    const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY)
    if (stored) {
      return JSON.parse(stored)
    }
    return undefined
  } catch (e) {
    console.error('loadFromStorage:', e)
    return undefined
  }
}

function loadSyncFromStorage() {
  try {
    const stored = window.localStorage.getItem(LOCAL_STORAGE_KEY_SYNC)
    if (stored) return new Date(stored)
    return undefined
  } catch (e) {
    console.error('loadFromStorage:', e)
    return undefined
  }
}

export const useSchoolStore = create((set, get) => ({
  loading: false,
  school: loadFromStorage(),
  lastSync: loadSyncFromStorage(),

  setSchool: (school) => {
    set({ school, loading: false, lastSync: new Date() })
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(school))
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_SYNC,
      new Date().toISOString(),
    )
  },

  removeSchool: () => {
    const school = get().school.name
    set({ school: undefined })
    if (school) useRecentBooksStore.getState().removeBookBySchool(school)
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(undefined))
  },
}))

export function useLoadSchool() {
  const showError = useSnackbar().enqueueSnackbar
  return async (code) => {
    useSchoolStore.setState({ loading: true })
    return axios
      .get(`/api/books/by-school/${code}`)
      .then((response) => {
        const school = {
          name: response.data.school.name,
          logo: response.data.school.logo,
          code,
        }
        useSchoolStore.getState().setSchool(school)
        response.data.books.forEach((book) => {
          useRecentBooksStore.getState().addBook({
            code: book.code,
            title: book.title,
            school: response.data.school.name,
            coverUrl: book.coverUrl,
            active: book.active,
          })
        })
        return school
      })
      .catch((e) => {
        let msg
        console.warn(e)
        switch (e.response.status) {
          case 404:
            msg = 'Ungültiger Schulcode!'
            break
          case 504:
            msg =
              'Der Server ist derzeit nicht verfügbar. Probieren Sie es in ein paar Minuten erneut.'
            break
          case 500:
          default:
            console.warn(e.response.status)
            msg = 'Ein unbekannter Fehler ist aufgetreten.'
        }
        useSchoolStore.setState({ loading: false })
        showError(msg, {
          variant: 'error',
          autoHideDuration: 8000,
        })
      })
  }
}
