import create from 'zustand'
import produce from 'immer'
import { devtools } from 'zustand/middleware'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useRecentBooksStore } from './RecentBooksStore'

export const useBookStore = create((set) => ({
  loaded: false,
  loading: false,
  page: 1,
  task: 0,
  orientation: 'landscape',

  school: {
    name: '',
    logo: '',
  },
  license: {
    code: '',
    active: false,
  },
  book: {
    pages: 28,
    levels: 12,
    level_for_page: [],
    page_for_level: [],
    level_display_offset: 0,
    special_pages: {},
    audio_file_per_task: false,
    has_audio_for_level_zero_from_page: -1,
    title: '',
    base_url: '',
    short_name: '',
    has_alternate_audio: false,
    audio_on_pages: 'ALL',
    hide_audio_on_last_pages: 0,
  },
  set: (fn) => set(produce(fn)),
  pageFlip: null, // used in the sidebar for level access
}))

export function useLoadBook() {
  const showError = useSnackbar().enqueueSnackbar
  const history = useHistory()
  return async (code, setLocalLoaded) => {
    useBookStore.setState({ loading: true, pageFlip: null })
    axios
      .get(`/api/book/${code}`)
      .then((response) => {
        useBookStore.setState({
          book: {},
          ...response.data,
          page: 1,
          task: 0,
          loaded: true,
          loading: false,
        })
        useRecentBooksStore.getState().addBook({
          code: response.data.license.code,
          title: response.data.book.title,
          school: response.data.school.name,
          coverUrl: `${response.data.book.base_url}/pages/${response.data.book.short_name}-0.png`,
        })
        setLocalLoaded(true)
        setTimeout(() => history.push(`/heft/${code}`), 200)
      })
      .catch((e) => {
        let msg
        console.warn(e)
        switch (e.response.status) {
          case 404:
            msg = 'Ungültiger Lizenzcode!'
            useRecentBooksStore.getState().markExpired({ code })
            break
          case 403:
            msg = 'Dieser Lizenzcode ist abgelaufen!'
            useRecentBooksStore.getState().markExpired({ code })
            break
          case 504:
            msg =
              'Der Server ist derzeit nicht verfügbar. Probieren Sie es in ein paar Minuten erneut.'
            break
          case 500:
          default:
            console.warn(e.response.status)
            msg = 'Ein unbekannter Fehler ist aufgetreten.'
        }
        useBookStore.setState({ loading: false })
        showError(msg, {
          variant: 'error',
          autoHideDuration: 8000,
        })
      })
  }
}
