import React, { useEffect, useMemo } from 'react'
import AppBar from '@material-ui/core/AppBar'
import { Toolbar } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ReactAudioPlayer from 'react-audio-player'
import { useBookStore } from '../Stores/BookStore'

function audioHiddenEvenOdd(page, audioOnPages) {
  switch (audioOnPages) {
    case 'ALL':
    default:
      return false
    case 'EVEN':
      return page % 2 !== 0
    case 'ODD':
      return page % 2 === 0
  }
}

function audioHiddenLastPages(page, pages, hideOnLastPages) {
  return page >= pages - hideOnLastPages
}

function pauseOtherPlayers(playingPlayer) {
  return () => {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 4; ++i) {
      // eslint-disable-next-line no-continue
      if (i === playingPlayer) continue
      const player = document.getElementById(`audio-player-${i}`)
      // eslint-disable-next-line no-unused-expressions
      player && player.pause()
    }
  }
}

export function Audio() {
  const task = useBookStore((state) => state.task)
  const baseUrl = useBookStore((state) => state.book.base_url)
  const shortName = useBookStore((state) => state.book.short_name)

  const level = useBookStore((state) => state.book.level_for_page[state.page])
  const nextLevel = useBookStore(
    (state) =>
      state.book.level_for_page[Math.min(state.page + 1, state.book.pages)],
  )
  const orientation = useBookStore((state) => state.orientation)
  const mp3PerTask = useBookStore((state) => state.book.audio_file_per_task)
  const alternateAudio = useBookStore((state) => state.book.has_alternate_audio)

  const audioOnPages = useBookStore((state) => state.book.audio_on_pages)

  useEffect(() => {
    if (task !== 0)
      setTimeout(() => document.getElementById('audio-player').play(), 400)
  }, [task])

  const src = useMemo(() => {
    if (mp3PerTask)
      return `${baseUrl}/audio/${shortName}-Level-${level}-${task + 1}.mp3`
    return `${baseUrl}/audio/${shortName}-Level-${level}.mp3`
  }, [level, task, mp3PerTask])

  const src2 = useMemo(() => {
    if (mp3PerTask || orientation === 'portrait') return null
    return `${baseUrl}/audio/${shortName}-Level-${nextLevel}.mp3`
  }, [nextLevel, task, mp3PerTask, orientation])

  const srcAlt = useMemo(
    () => `${baseUrl}/audio/${shortName}-Level-${level}-alt.mp3`,
    [level],
  )

  const src2Alt = useMemo(
    () => `${baseUrl}/audio/${shortName}-Level-${nextLevel}-alt.mp3`,
    [nextLevel],
  )

  // TODO: none of this currently supports mp3PerTask.
  const innerAudioStyle = {
    width: alternateAudio ? '50%' : '100%',
    padding: alternateAudio ? '0 4px' : 0,
  }
  return (
    <div style={{ width: '100%', textAlign: 'initial' }}>
      <div
        id="left-players"
        style={
          orientation === 'landscape' &&
          (level !== nextLevel || audioOnPages !== 'ALL')
            ? { width: '50%', display: 'inline-block' }
            : undefined
        }
      >
        {(orientation === 'portrait' || audioOnPages !== 'EVEN') && (
          <ReactAudioPlayer
            style={innerAudioStyle}
            src={src}
            controls
            title={`Level ${level}`}
            id="audio-player-1"
            onPlay={pauseOtherPlayers(1)}
          />
        )}
        {alternateAudio &&
          (orientation === 'portrait' || audioOnPages !== 'EVEN') && (
            <ReactAudioPlayer
              style={innerAudioStyle}
              src={srcAlt}
              controls
              title={`Level ${level} alternativ`}
              id="audio-player-2"
              onPlay={pauseOtherPlayers(2)}
            />
          )}
      </div>
      {orientation === 'landscape' &&
        (level !== nextLevel || audioOnPages === 'EVEN') && (
          <div
            id="right-players"
            style={{ width: '50%', display: 'inline-block' }}
          >
            <ReactAudioPlayer
              style={innerAudioStyle}
              src={src2}
              controls
              title={`Level ${nextLevel}`}
              id="audio-player-3"
              onPlay={pauseOtherPlayers(3)}
            />
            {alternateAudio && (
              <ReactAudioPlayer
                style={innerAudioStyle}
                src={src2Alt}
                controls
                title={`Level ${nextLevel} alternativ`}
                id="audio-player-4"
                onPlay={pauseOtherPlayers(4)}
              />
            )}
          </div>
        )}
    </div>
  )
}

export function AudioPlayer({ className }) {
  const task = useBookStore((state) => state.task)
  const level = useBookStore((state) => state.book.level_for_page[state.page])
  const mp3PerTask = useBookStore((state) => state.book.audio_file_per_task)
  const showLevelZeroAudio = useBookStore(
    (state) =>
      state.book.has_audio_for_level_zero_from_page >= 0 &&
      state.page >= state.book.has_audio_for_level_zero_from_page &&
      state.page < state.book.pages - 2,
  )
  const hideAudio = useBookStore(
    (state) =>
      audioHiddenLastPages(
        state.page,
        state.book.pages,
        state.book.hide_audio_on_last_pages,
      ) ||
      (state.orientation === 'portrait' &&
        audioHiddenEvenOdd(state.page, state.book.audio_on_pages)),
  )

  return (
    <AppBar
      className={`${className} print-hidden`}
      position="fixed"
      color="transparent"
      style={{ top: 'auto', bottom: 0 }}
    >
      <Toolbar variant="dense">
        {(level > 0 || showLevelZeroAudio) && !hideAudio && (
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            {mp3PerTask && (
              <Grid item>
                <FormControl>
                  <Select
                    id="task-select"
                    value={task}
                    onChange={(e) => {
                      useBookStore.setState({ task: e.target.value })
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      Anleitung
                    </MenuItem>
                    {[...Array(12)].map((_, i) => (
                      <MenuItem key={i + 1} value={i + 1}>
                        Plättchen {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid
              item
              style={{
                minWidth: '100px',
                width: '95%',
                paddingTop: 4,
                paddingBottom: 0,
              }}
            >
              <Audio />
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  )
}
