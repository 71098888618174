import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Container from '@material-ui/core/Container'
import { Typography } from '@material-ui/core'
import { useBookStore } from '../Stores/BookStore'

export function Sidebar() {
  const [
    levels,
    page,
    pageForLevel,
    levelForPage,
    specialPages,
    levelDisplayOffset,
    pageFlip,
    url,
    shortName,
    license,
    school,
  ] = useBookStore((state) => [
    state.book.levels,
    state.page,
    state.book.page_for_level,
    state.book.level_for_page,
    state.book.special_pages,
    state.book.level_display_offset,
    state.pageFlip,
    state.book.base_url,
    state.book.short_name,
    state.license,
    state.school,
  ])

  const menuItems = []
  menuItems.push(
    <MenuItem key={0} value={0}>
      Deckblatt
    </MenuItem>,
  )
  for (let i = 0; i < levels; i++) {
    menuItems.push(
      <MenuItem key={i + 1} value={i + 1}>
        Level {i + 1 + levelDisplayOffset}
      </MenuItem>,
    )
  }
  menuItems.push(
    ...Object.keys(specialPages).map((name) => (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    )),
  )

  return (
    <Container style={{ paddingBottom: 16 }}>
      <div
        style={{
          backgroundImage: `url(${url}/pages/${shortName}-${0}.png)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          minHeight: 256,
        }}
      />
      <FormControl style={{ paddingTop: 16, width: 200 }}>
        <Select
          value={levelForPage[page]}
          onChange={(e) => {
            const newLevel = e.target.value
            const newPage =
              typeof newLevel === 'number'
                ? pageForLevel[newLevel]
                : specialPages[newLevel]
            useBookStore.setState({ page: newPage })
            pageFlip.getPageFlip().flip(newPage, 'top')
          }}
        >
          {menuItems}
        </Select>
      </FormControl>
      <div style={{ bottom: 16, position: 'absolute' }}>
        <Typography
          style={license.active ? undefined : { color: 'red' }}
          variant="body2"
        >
          <span style={{ userSelect: 'none' }}>Code: </span>
          {license.code} {!license.active && '(Läuft bald ab)'}
        </Typography>
        <br />
        <Typography style={{ userSelect: 'none' }} variant="body2">
          <em>Lizensiert für: </em>
        </Typography>
        <Typography style={{ userSelect: 'none' }} variant="body1">
          {school.name}
        </Typography>
        {school.logo && (
          <img
            alt="Schullogo"
            src={school.logo}
            style={{ userSelect: 'none', maxWidth: 150, paddingTop: 8 }}
          />
        )}
      </div>
    </Container>
  )
}
